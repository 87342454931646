




























































  import { Component, Prop, Vue } from 'vue-property-decorator'
  import Meeting from '@/customer-portal/meeting/entities/meeting'
  import Participant from '@/customer-portal/meeting/entities/participant'
  import AddOn from '@/customer-portal/meeting/entities/add-on'
  import { getMeetingsById, getParticipantsByMeetingId, getAddonsByMeetingId } from '@/customer-portal/meeting/services/meeting-rest'
  import VueMarkdown from 'vue-markdown-v2'
  import { formatDate } from '@/common'
  import moment from 'moment'
  import { ParticipantStatus, getParticipantStatuses, ParticipantMetric } from '@/customer-portal'

  type MetricsChartData = Array<{name: string, data: Array<{x:number, y:number}>}>

  // @ts-ignore
  @Component({ components: { VueMarkdown } })
  export default class MeetingView extends Vue {

    @Prop({ type: String, required: true })
    public id!: string

    private loaded = false

    private meeting: Meeting = new Meeting()

    private participants: Participant[] = []

    private series: any = [] //Array<{ name?: string; color?: string; data: any[] }> = [{ data: [] }]
    private consumerScores: MetricsChartData = []
    private consumerPacketsLost: MetricsChartData = []
    private consumerPliCount: MetricsChartData = []
    private consumerFirCount: MetricsChartData = []
    private producerPliCount: MetricsChartData = []
    private producerFirCount: MetricsChartData = []

    private metricDisplayGroups: Array<{series: MetricsChartData, title: string, name: string}> = []

    private get renderBasicClaim(): string {
      if (!this.loaded) {
        return 'Loading data...'
      }
      return `
| ${this.t('meeting.view.description')} | ${this.t('meeting.view.value')} |
| ---------------------------------------------------------- | ---------------------------------------------------------------------------- |
| ${this.t('meeting.view.claims.meeting-started')}           | ${formatDate(this.meeting.startTimestamp)}                                   |
| ${this.t('meeting.view.claims.meeting-ended')}             | ${formatDate(this.meeting.endTimestamp)}                                     |
| ${this.t('meeting.view.claims.meeting-duration')}          | ${this.duration(this.meeting.startTimestamp, this.meeting.endTimestamp)}     |
| ${this.t('meeting.view.claims.number-of-participants')}    | ${this.meeting.participantCount}                                             |
| ${this.t('meeting.view.claims.meeting-unique-id')}         | ${this.meeting.id}                                                           |
| ${this.t('meeting.view.claims.meeting-id')}                | ${this.meeting.meetingId}                                                    |
| ${this.t('meeting.view.claims.meeting-alias')}             | ${this.meeting.meetingAlias}                                                 |
| ${this.t('meeting.view.claims.pbx-id')}                    | ${this.meeting.pbxId}                                                        |
| ${this.t('meeting.view.claims.owner-id')}                  | ${this.meeting.meetingOwnerId}                                               |
| ${this.t('meeting.view.claims.host-language')}             | ${this.meeting.metaData?.locale}                                             |
| ${this.t('meeting.view.claims.bridge-url')}                | ${this.meeting.metaData?.bridgeUrl}                                          |
| ${this.t('meeting.view.claims.microphone-enabled')}        | ${this.meeting.metaData?.isMicrophoneDisabledOnJoin}                         |
| ${this.t('meeting.view.claims.video-enabled')}             | ${this.meeting.metaData?.isVideoDisabledOnJoin}                              |
| ${this.t('meeting.view.claims.password-enabled')}          | ${this.meeting.metaData?.isPasswordProtectionEnabled}                        |
    `
    }

    private get renderParticipants(): string {
      if (!this.loaded) {
        return this.t('meeting.loading-data')
      }

      const participantInfo: string = this.participants.map((participant: Participant, index: number) => {
      const browser = participant.clientData.browser
      const inputDevices = participant.clientData.inputDevices

      return `
### :fa-user: ${index + 1}. ${this.renderModeratorOrParticipant(index)}: ${participant.id}

  | ${this.t('meeting.view.description')} | ${this.t('meeting.view.value')} |
  | ----------------------------------------------------- | --------------------------------------------------------------------------- |
  | ${this.t('meeting.view.participant.joined')}      | ${formatDate(participant.joinTimestamp)}                                    |
  | ${this.t('meeting.view.participant.leaved')}      | ${formatDate(participant.leaveTimestamp)}                                   |
  | ${this.t('meeting.view.participant.duration')}    | ${this.duration(participant.joinTimestamp, participant.leaveTimestamp)}     |
  | ${this.t('meeting.view.participant.browser')}     | ${browser ? `${browser.name} ${browser.version}` : ''}                      |
  | ${this.t('meeting.view.participant.audioInput')}  | ${inputDevices.audio.map((d) => d.label).join(', ')}                        |
  | ${this.t('meeting.view.participant.videoInput')}  | ${inputDevices.video.map((d) => d.label).join(', ')}                        |
      `
      }).join('')

      return participantInfo
    }

    private chartOptions: any =  {
      chart: {
        height: 350,
        type: 'rangeBar'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          // @ts-ignore
          formatter: (value) => {
            return moment(value).format('HH:mm:ss')
           }
          }
        },
      stroke: {
        width: 1
      },
      fill: {
        type: 'solid',
        opacity: 0.6
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      dataLabels: {
        enabled: true,
        // @ts-ignore
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          return w.config.series[seriesIndex].name + ': ' + moment.utc(moment(value[1]).diff(moment(value[0]))).format('HH:mm:ss')
        }
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: 'HH:mm:ss',
        }
      }
    }

    private valueDisplayOptions: any =  {
      chart: {
        type: 'line'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (value: number) => {
            return moment(value).format('HH:mm:ss')
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        labels: {
          formatter: (value: number) => {
            return `${value}`
          }
        }
      }
    }

    private accumulatingDisplayOptions: any =  {
      legend: {
        show: true
      },
      chart: {
        type: 'line'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (value: number) => {
            return moment(value).format('HH:mm:ss')
          }
        }
      },
      yaxis: {
        min: 0,
        }
      }


    private get meetingIdForHeader(): string {
      if (this.loaded) {
        return `(${this.meeting.meetingId})`
      }
      return ''
    }

    private get meetingId(): string {
      if (this.loaded) {
        return this.meeting.meetingId
      }
      return ''
    }

    private renderModeratorOrParticipant(index: number): string {
      if (index === 0) {
        return this.t('meeting.label.moderator')
      }
      else {
        return this.t('meeting.label.participant')
      }
    }

    private duration(start: string, end: string): string {
      return moment.utc(moment(end).diff(moment(start))).format('HH:mm:ss')
    }

    private t(key: string): string {
      const translation: string | undefined = this.$i18n.translate(key)
      return translation ? translation : ''
    }

    private backToMeetingSearch(): void {
      if (this.meeting && this.meeting.meetingId) {
        this.$router.push({ path: '/meetings', query: { meetingId: this.meeting.meetingId } }).catch()
      } else {
        this.$router.push('/meetings').catch()
      }
    }

    private mounted(): void {
      getMeetingsById(this.id)
        .then(meeting => this.meeting = meeting)
        .then(() => getParticipantsByMeetingId(this.id))
        .then((participants: Participant[]) => {
          this.participants = participants.sort((p1, p2) => moment(p1.joinTimestamp).diff(moment(p2.joinTimestamp)))

          const participantsData: any[] = participants.map((participant, index) => {
            return {
              x: `P${index + 1}/${participant.id}`,
              y: [
                new Date(participant.joinTimestamp).getTime(),
                new Date(participant.leaveTimestamp).getTime()
              ]
            }
          })

          this.series = [{ color: '#171e42', name: 'In Meeting', data: participantsData }]
        })
       .then(() => getParticipantStatuses(this.meeting.meetingSessionId))
       .then(this.groupByParticipant)
       .then((participantStatuses) => {
         this.consumerScores = this.participantStatusesToMetric(participantStatuses,'consumerScore')
         this.consumerPacketsLost = this.participantStatusesToMetric(participantStatuses,'consumerPacketsLost')
         this.consumerFirCount = this.participantStatusesToMetric(participantStatuses,'consumerFirCount')
         this.consumerPliCount = this.participantStatusesToMetric(participantStatuses,'consumerPliCount')
         this.producerFirCount = this.participantStatusesToMetric(participantStatuses,'producerFirCount')
         this.producerPliCount = this.participantStatusesToMetric(participantStatuses,'producerPliCount')
         this.metricDisplayGroups= [
           { series: this.setValueChangeFromPreviousData(this.consumerPacketsLost), title: 'consumerPacketLoss', name: 'metric-packetloss' },
           { series: this.setValueChangeFromPreviousData(this.consumerPliCount), title: 'consumerPli', name: 'metric-consumer-pli' },
           { series: this.setValueChangeFromPreviousData(this.consumerFirCount), title: 'consumerFir', name: 'metric-consumer-fir' },
           { series: this.setValueChangeFromPreviousData(this.producerPliCount), title: 'producerPli', name: 'metric-producer-pli' },
           { series: this.setValueChangeFromPreviousData(this.producerFirCount), title: 'producerFir', name: 'metric-producer-fir' }
         ]
       })
      .then(() => getAddonsByMeetingId(this.id))
      .then((addOns: AddOn[]) => {
        // TODO: need addon join and leave time
        addOns.forEach((addOn, index) => {
          const addOnsData = addOn.usage.map(usage => {
            return {
              x: this.series[0].data.find((entry: { x: string, y: string }) => entry.x.includes(usage.hostParticipantId))?.x ?? 'undefined',
              y: [
                new Date(usage.startTimestamp).getTime(),
                new Date(usage.endTimestamp).getTime()
              ]
            }
          })

          const factor = (10 / addOn.usage.length) * index
          this.series.push({ color: `hsl(38, 100%, ${48 - factor}%)`, name: addOn.name + ' addon', data: addOnsData })
        })
      })
      .catch((e) => {
        console.error(e)
        this.$toast.error('meeting.cant-request-meeting-data')
      })
      .finally(() => this.loaded = true)
    }

    private participantStatusesToMetric = (groupedByParticipant: Array<{participant: string, statuses: Array<ParticipantStatus>}>, participantMetric: ParticipantMetric) =>
        groupedByParticipant.map(entry => {
          return {
            name: entry.participant,
            data: entry.statuses.map(status => {return { x: status.timeStamp, y: status[participantMetric] }}) }
        })

    private setValueChangeFromPreviousData = (chartData: MetricsChartData) =>
      chartData.map(participant => {
        let prevY = 0
        return {
          name: participant.name,
          data: participant.data.map(dataPair => {
            const currY = dataPair.y - prevY
            prevY = dataPair.y
            return { x: dataPair.x, y: currY }
          })
        }
      })


    private groupByParticipant = (rawData: Array<ParticipantStatus>) => {
      const result: Array<{participant: string, statuses: Array<ParticipantStatus>}> = []
      rawData.forEach((participantStatus: ParticipantStatus) => {
        const participantId: string = participantStatus.participantId
        const participantEntry = result.find(x => x.participant === participantId)
        if (participantEntry) {
          participantEntry.statuses.push(participantStatus)
        } else {
          result.push({ participant: participantId, statuses: [participantStatus] })
        }
      })
      result.forEach(entry => entry.statuses.sort((s1: ParticipantStatus, s2: ParticipantStatus) => s1.timeStamp - s2.timeStamp))
      return result
    }
  }
