import { RequestStatus, RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import BridgeResponse from '@/customer-portal/overview/entities/bridge-response'
import Bridge from '@/customer-portal/overview/entities/bridge'
import NeonConfiguration from '@/customer-portal/overview/entities/neon-configuration'

const restModule: RestModule = getModule(RestModule, store)

export const getBridges: () => Promise<BridgeResponse> = (): Promise<BridgeResponse> => restModule.get({
  url: '/bridges',
  pathParams: {},
  queryParams: {},
  cacheFunc: () => []
}).then((result: RestResult<BridgeResponse>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return new BridgeResponse()
    }
  }
})

export const updateBridge: (id: string, bridge: Bridge) => Promise<Bridge> =
  (id: string, bridge: Bridge): Promise<Bridge> => restModule.put({
    url: '/bridges/:bridgeId',
    pathParams: { bridgeId: id },
    body: bridge
  }).then((result: RestResult<Bridge>) => {
    return result.data
  })

export const getNeonConfiguration: () => Promise<NeonConfiguration> = (): Promise<NeonConfiguration> => restModule.get({
  url: '/neon/configuration',
  pathParams: {},
  queryParams: {},
  cacheFunc: () => []
}).then((result: RestResult<NeonConfiguration>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return new NeonConfiguration()
    }
  }
})

export const updateNeonConfiguration: (neonConfiguration: NeonConfiguration) => Promise<NeonConfiguration> =
  (neonConfiguration: NeonConfiguration): Promise<NeonConfiguration> => restModule.put({
    url: '/neon/configuration',
    pathParams: {},
    body: neonConfiguration
  }).then((result: RestResult<NeonConfiguration>) => {
    return result.data
  })
