














































































































import { Component, Prop, Ref } from 'vue-property-decorator'
import { createNews, deleteNews, getFiles, News, updateNews } from '@/customer-portal'
import { SfEditor } from '@/components'
import { SfForm, SfMultiDropdownField } from '@starface/sf-components/types/components'
import SfDropdownField from '@starface/sf-components/src/components/form/fields/sf-field-dropdown.vue'
import { getNewsById } from './services/news-rest'
import { Channel, Icon, NewsLanguageSplit, Theme } from './entities/news'
import moment from 'moment'

@Component({})
export default class NewsEditor extends SfEditor {

  @Ref()
  public readonly newsForm!: SfForm

  @Ref()
  public readonly currentIcon!: SfDropdownField

  @Ref()
  public readonly currentChannels!: SfMultiDropdownField

  @Ref()
  public readonly currentTheme!: SfDropdownField

  @Ref()
  public readonly currentVideo!: SfDropdownField

  @Prop({ type: String, required: true })
  public id!: string

  public content = new NewsLanguageSplit()

  protected entityType = 'news'

  private iconChoices: { key: Icon, value: string }[] = [
    { key: 'help', value: 'help' },
    { key: 'info', value: 'info' },
    { key: 'exclamation triangle', value: 'exclamation triangle' }
  ]

  private selectedChannels: { key: Channel, value: string }[] = []

  private channelChoices: { key: Channel, value: string }[] = [
    { key: 'sf-app', value: 'STARFACE Desktop App' },
    { key: 'home', value: 'NEON HOME' }
  ]

  private fileChoices: { key: string, value: string }[] = [{ key: '', value: '' }]

  private themeChoices: { key: Theme, value: string }[] = [
    { key: '', value: 'None' },
    { key: 'xmas', value: 'Christmas' }
  ]

  protected updateRestFunction = updateNews

  protected createRestFunction = createNews

  protected deleteRestFunction = deleteNews

  protected getData(): News | undefined {
    if (!this.newsForm.hasErrors()) {
      const data = this.newsForm.getData<NewsLanguageSplit>()
      const startTimestamp = moment(data.startDate).valueOf()
      const endTimestamp = moment(data.endDate).valueOf()
      if (endTimestamp <= startTimestamp) {
        this.newsForm.addValidationErrorFromBackend('endDate', this.$t('news.table.endDateNotGreater')!)
        return undefined
      }
      const defaultNews = new News()
      return {
        id: this.id,
        name: data.name,
        lastUpdateEpochMillis: defaultNews.lastUpdateEpochMillis,
        startTimestamp,
        endTimestamp,
        icon: data.icon.key,
        headlineText: { DE: data.headlineTextDE, FR: data.headlineTextFR, EN: data.headlineTextEN, },
        descriptionText: { DE: data.descriptionTextDE, FR: data.descriptionTextFR, EN: data.descriptionTextEN, },
        linkText: { DE: data.linkTextDE, FR: data.linkTextFR, EN: data.linkTextEN, },
        internationalizedLinks: { DE: data.linkDE, FR: data.linkFR, EN: data.linkEN },
        url: data.url,
        theme: data.theme.key,
        videoId: data.video.key || undefined,
        channels: data.channels.map(selectedChannels => selectedChannels.key)
      }
    }
    return undefined
  }

  private loadData(): void {
    getNewsById(this.id)
        .then(apiNews => {
          this.content = {
            name: apiNews.name,
            startDate: moment(apiNews.startTimestamp).format('YYYY-MM-DD'),
            endDate: moment(apiNews.endTimestamp).format('YYYY-MM-DD'),
            icon: this.iconChoices.find((choice) => choice.key === apiNews.icon) ?? this.iconChoices[0],
            headlineTextDE: apiNews.headlineText.DE,
            headlineTextFR: apiNews.headlineText.FR,
            headlineTextEN: apiNews.headlineText.EN,
            descriptionTextDE: apiNews.descriptionText.DE,
            descriptionTextFR: apiNews.descriptionText.FR,
            descriptionTextEN: apiNews.descriptionText.EN,
            linkTextDE: apiNews.linkText.DE,
            linkTextFR: apiNews.linkText.FR,
            linkTextEN: apiNews.linkText.EN,
            linkDE: apiNews.internationalizedLinks.DE,
            linkFR: apiNews.internationalizedLinks.FR,
            linkEN: apiNews.internationalizedLinks.EN,
            url: apiNews.url,
            video: this.fileChoices.find(choice => choice.key === apiNews.videoId) ?? this.fileChoices[0],
            theme: this.themeChoices.find((choice) => choice.key === apiNews.theme) ?? this.themeChoices[0],
            channels: this.channelChoices.map(choice => apiNews.channels.includes(choice.key) ? {
              ...choice,
              selected: true
            } : choice)
          }
          this.selectedChannels = this.content.channels
        })
        .catch()
  }

  public mounted(): void {
    this.newsForm.clearForm()
    this.newsForm.setLoading()
    getFiles()
        .then(files => files.forEach(({ id, fileName }) => this.fileChoices.push({
          key: id,
          value: `${fileName} (${id})`
        })))
        .then(() => {
          if (!this.isNew(this.id)) {
            this.loadData()
          } else {
            this.selectedChannels = this.channelChoices
          }
        })
        .then(() => this.newsForm.setReady())
        .catch()

    this.isMounted = true
  }
}
