



































import { Ref, Component, Prop } from 'vue-property-decorator'
import { SfEditor } from '@/components'
import { BaseRestEntity } from '@/common'
import { SfForm } from '@starface/sf-components/types/components'
import { getAdmin, updateAdmin } from '@/customer-portal/admin/services/admin-rest'
import Admin from '@/customer-portal/admin/entities/admin'
import { RestModule, store } from '@/services'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class UserProfile extends SfEditor {

  @Ref()
  public readonly detailForm!: SfForm

  private restModule: RestModule = getModule(RestModule, store)

  protected entityType = 'account'

  protected account: Admin = new Admin()

  protected id = ''

  protected updateRestFunction: ((id: string, data: any) => Promise<string>) | undefined
    = updateAdmin

  protected createRestFunction: ((data: any) => Promise<string>) | undefined
    = undefined

  protected deleteRestFunction: ((id: string) => Promise<string>) | undefined
    = undefined

  protected getData(): BaseRestEntity | undefined {
    if (!this.detailForm.hasErrors()) {
      return this.detailForm.getData<Admin>()
    }
    return undefined
  }

  protected created(): void {

    getAdmin(this.restModule.getAdminId).then(loadedAccount => {
      this.account = loadedAccount
      this.id = loadedAccount.id
      }).catch()
  }
}
