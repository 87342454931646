import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { InternalMeetingSearch, MeetingEditor } from '../.'
import { MeetingOverview } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class MeetingRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/internal/meetings',
      component: MeetingOverview,
      children: [
        {
          path: ':id',
          component: MeetingEditor,
          props: true
        },
        {
          path: '/',
          component: InternalMeetingSearch,
          meta: { entity: 'internal-meeting' }
        }
        ],
      props: true
    }
  }
}
