import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { AdminOverview, AdminList, AdminEditor } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class AdminRoutes implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/admins',
      component: AdminOverview,
      children: [
        {
          path: ':id',
          component: AdminEditor,
          props: true
        },
        {
          path: '/',
          component: AdminList,
          meta: { entity: 'admin' }
        }],
      props: true
    }
  }
}
