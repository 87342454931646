import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import { VideoFile } from '@/customer-portal'

const restModule: RestModule = getModule(RestModule, store)

export const createFile = (newFile: FormData): Promise<VideoFile> => restModule.post({
    url: '/files',
    pathParams: {},
    body: { newFile }
}).then((result: RestResult<VideoFile>) => result.data)

export const getFiles = (): Promise<VideoFile[]> => restModule.get({
    url: '/files',
    pathParams: {},
    queryParams: {},
    cacheFunc: () => []
}).then((result: RestResult<VideoFile[]>) => result.data)

export const getFileById = (id: string): Promise<VideoFile> => restModule.get({
    url: '/files/:fileId',
    pathParams: { fileId: id },
    queryParams: {},
    cacheFunc: () => []
}).then((result: RestResult<VideoFile>) => result.data)

export const getVideoStreamUrlForFile = (id: string): Promise<any> => restModule.get({
    url: '/files/:fileId/stream',
    pathParams: { fileId: id },
    queryParams: {},
    cacheFunc: () => []
}).then((result: RestResult<any>) => result.data)

export const deleteFile = (id: string): Promise<void> => restModule.delete({
    url: '/files/:fileId',
    pathParams: { fileId: id }
})
