import { Container } from 'typedi'
import { MeetingRoute } from './services/meeting-route'

Container.import([
  MeetingRoute
])

export { default as MeetingOverview } from './meeting-overview.vue'
export { default as MeetingSearch } from './meeting-search.vue'
export { default as MeetingView } from './meeting-view.vue'
