





































import { SfList } from '@/components'
import { Component, Ref } from 'vue-property-decorator'
import { SfTable } from '@starface/sf-components/types/components'
import { deleteFile, getFiles, VideoFile } from '@/customer-portal'
import { SfContentWrapper, SfStringCell } from '@starface/sf-components/src/components/index'
import moment from 'moment'

@Component({
  components: { SfContentWrapper, SfStringCell }
})
export default class FileList extends SfList {

  @Ref()
  private readonly fileTable!: SfTable

  protected entityType = 'file'

  protected deleteRestFunction = async (id: string) => deleteFile(id).then(() => this.loadFiles())

  private files: VideoFile[] = []

  private async loadFiles() {
    this.fileTable.setLoading()
    await getFiles().then(result => {
      this.files = result.map(apiFile => {
        apiFile.creationTime = moment(apiFile.creationTime).format('YYYY-MM-DD')
        return apiFile
      })
      this.fileTable.setReady()
    })
  }

  public mounted(): void {
    this.loadFiles().then(() => this.isMounted = true)
  }


  protected get selectedEntities(): VideoFile[] {
    if (this.isMounted) {
      return this.fileTable.getSelectedRows() as VideoFile[]
    }
    return []
  }

  protected commaSeparatedNames(): string {
    return this.selectedEntities.map(file => file.fileName+`(${file.id})`).join(',')
  }


}
