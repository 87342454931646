































import { Component, Vue } from 'vue-property-decorator'
import NeonConfiguration from '@/customer-portal/overview/entities/neon-configuration'
import { updateNeonConfiguration } from '@/customer-portal/overview/services/overview-rest'

@Component({})
export default class PowerNeonOffDialogue extends Vue {

  private inProcess = false

  private confirmationText = ''

  private confirmationTextDoesNotMatch = false

  private configuration: {available: boolean; id: string} = { available: false, id: '-1' }

  public saveAndClose(): void {
    this.confirmationTextDoesNotMatch = false
    if (this.validateConfirmationText()) {
      this.inProcess = true

      this.setNeonAvailability(!this.configuration.available).then(configuration => {
        this.configuration = configuration
        this.inProcess = false
        this.confirmationText = ''
        this.$emit('neonConfiguration', this.configuration)
        this.emitCloseEvent()
      }).catch()
    } else {
      this.confirmationTextDoesNotMatch = true
    }
  }

  public emitCloseEvent(): void {
    this.$emit('close')
  }

  private get isAvailable(): boolean {
    return this.configuration && this.configuration.available
  }

  public setConfiguration(configuration: NeonConfiguration): void {
    this.configuration = configuration
  }

  private async setNeonAvailability(isAvailable: boolean): Promise<NeonConfiguration> {
    const updatedNeonConfiguration: NeonConfiguration = {
      id: this.configuration.id,
      available: isAvailable
    }
    return updateNeonConfiguration(updatedNeonConfiguration)
  }

  private validateConfirmationText(): boolean {
    return this.configuration.available && this.confirmationText === 'disable NEON meetings'
            || !this.configuration.available && this.confirmationText === 'enable NEON meetings'
  }
}
