import { Container } from 'typedi'
import { AdminRoutes } from './services/admin-routes'
// import { ManagerSSE } from './services/manager-sse';
export { getAdmin, getAdmins, createAdmin, deleteAdmin } from './services/admin-rest'

Container.import([
  AdminRoutes
])

export { default as Admin } from './entities/admin'

export { default as AdminList } from './admin-list.vue'
export { default as AdminOverview } from './admin-overview.vue'
export { default as AdminEditor } from './admin-editor.vue'
