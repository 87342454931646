export interface PbxUpdate {
  serverLicenseKey: string;
  configuration: Configuration;
}

export interface Pbx {
  id: string;
  serverLicenseKey: string;
  enabled: boolean;
  configuration: Configuration;
  publicKeys: PublicKey[];
}

export interface Configuration {
  allowedAddOns: Array<string>
  description: string;
  maxMeetingDuration: number;
  maxMeetingSize: number;
  maxRoomsByUser: number;
  paymentPlan: 'FREEMIUM' | 'FULL_PLAN'
  schedulingEnabled: boolean;
}

export interface PublicKey {
  algorithm: string;
  base64EncodedPublicSigningKey: string;
  metadata: {
    lastUsed: string;
  };
}

export const emptyPbx: () => Pbx = () => {
  return {
    id: '',
    serverLicenseKey: '',
    enabled: false,
    configuration: {
      description: '',
      allowedAddOns: [],
      maxMeetingDuration: 45,
      maxMeetingSize: 3,
      maxRoomsByUser: 20,
      paymentPlan: 'FREEMIUM',
      schedulingEnabled: true
    },
    publicKeys: []
  }
}
