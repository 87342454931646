









































































import { Ref, Component, Vue } from 'vue-property-decorator'
import { getBridges, getNeonConfiguration, updateBridge } from '@/customer-portal/overview/services/overview-rest'
import Bridge from '@/customer-portal/overview/entities/bridge'
import BridgeResponse from '@/customer-portal/overview/entities/bridge-response'
import { SfModal } from '@starface/sf-components/types/components'
import PowerNeonOffDialogue from '@/customer-portal/overview/power-neon-off-dialogue.vue'
import NeonConfiguration from '@/customer-portal/overview/entities/neon-configuration'

// @ts-ignore
import sslCertificate from 'get-ssl-certificate'

@Component({ components: { PowerNeonOffDialogue } })
export default class Overview extends Vue {

  @Ref()
  private powerNeonOff!: PowerNeonOffDialogue

  @Ref()
  private powerNeonOffDialogue!: SfModal

  private neonConfiguration: { available: boolean; id: string } = { available: false, id: '-1' }

  private bridges: Bridge[] = []

  private isConfigAvailable = false

  private mounted(): void {
    this.reloadBridges().catch()
    getNeonConfiguration().then((configuration: NeonConfiguration) => {
      this.isConfigAvailable = true
      this.configuration = configuration
    }).catch()
  }

  private async reloadBridges(): Promise<void> {
    setTimeout(async () => {
      const loadedBridge: BridgeResponse = await getBridges()
      this.bridges = await Promise.all(loadedBridge.items.map(async bridge => {

        bridge.certValid = true
        const host = bridge.bridgeUrl.replace(/wss:\/\//g, '').replace(/:443/g, '')
        sslCertificate.get(host).catch(() => bridge.certValid = false)

        return bridge
      }))
    }, 500)
  }


  private get hasAdminRole(): boolean {
    return this.$rest.hasAdminOrGivenRole('ROLE_ADMIN')
  }

  public addToLoadBalancer(bridge: Bridge): void {
    bridge.state = 'IN_LOAD_BALANCER'
    updateBridge(bridge.id, bridge).then(() =>
        this.reloadBridges().catch()
    ).catch()
  }

  public removeFromLoadBalancer(bridge: Bridge): void {
    bridge.state = 'REMOVED_FROM_LOAD_BALANCER'
    updateBridge(bridge.id, bridge).then(() =>
        this.reloadBridges().catch()
    ).catch()
  }

  public stopBridge(bridge: Bridge): void {
    bridge.state = 'STOPPED'
    updateBridge(bridge.id, bridge).then(() =>
        this.reloadBridges().catch()
    ).catch()
  }

  private setConfiguration(newConfiguration: NeonConfiguration): void {
    this.configuration = newConfiguration
  }

  public set configuration(newConfiguration: NeonConfiguration) {
    this.neonConfiguration = newConfiguration
  }

  public get configuration(): NeonConfiguration {
    return this.neonConfiguration
  }

  private enablePowerOffDialog(): void {
    this.powerNeonOff.setConfiguration(this.configuration)
    this.powerNeonOffDialogue.toggleHidden()
  }

  private async copyHostToClipboard(url: string): Promise<void> {
    this.$toast.info('overview.host-copied')
    const copyText: string = url.replace('wss://', '').replace(':443', '')
    navigator.clipboard.writeText(copyText).catch()
  }

  private closePowerOffDialog(): void {
    this.powerNeonOffDialogue.toggleHidden()
  }
}
