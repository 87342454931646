import Router, { Route } from 'vue-router'
import { RestModule } from '../communication/rest-module'
import { getModule } from 'vuex-module-decorators'

export const registerRouteRules: (router: Router) => void = (router: Router) => {
  const processBackendUrlRetrieval: (to: Route) => void = (to: Route) => {
    const restModule: RestModule = getModule(RestModule)
    restModule.configureBackendUrls().catch()
  }

  const processJwtTokenRetrieval: (to: Route) => void = (to: Route) => {
    const restModule: RestModule = getModule(RestModule)
    restModule.storeTokenFromCookie()
  }

  router.beforeEach((to: Route, from: Route, next) => {
    processJwtTokenRetrieval(to)
    processBackendUrlRetrieval(to)
    next()
  })
}
