import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'

const restModule: RestModule = getModule(RestModule, store)

export const getMeetingByMeetingId: (meetingId: string) => Promise<RestResult<any>> = (meetingId: string): Promise<RestResult<any>> => restModule.get({
  url: '/internal/meetings/:meetingId',
  timeout: 1000 * 20,
  pathParams: { meetingId },
  queryParams: {
  },
  cacheFunc: () => {
    // trick to make promise reject: cacheFunc is required and is used on error...
    throw Error()
  }
})

export const updateMeetingByMeetingId: (meetingId: string, data: any) => Promise<any> = (meetingId: string, data: any): Promise<any> => restModule.put({
  url: '/internal/meetings/:meetingId',
  pathParams: { meetingId },
  body: data,
}).then((result: RestResult<any>) => result.data)
