import { RequestStatus, RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import MeetingResponse from '@/customer-portal/meeting/entities/meeting-response'
import Meeting from '@/customer-portal/meeting/entities/meeting'
import Participant from '@/customer-portal/meeting/entities/participant'
import AddOn from '@/customer-portal/meeting/entities/add-on'
import moment from 'moment'

const restModule: RestModule = getModule(RestModule, store)

const startDate:  () => Date = () => {
  return moment().subtract(3, 'weeks').toDate()
}

export const getMeetingsByMeetingId: (meetingId: string) => Promise<MeetingResponse> = (meetingId: string): Promise<MeetingResponse> => restModule.get({
  url: '/reportings/meetings',
  pathParams: {},
  timeout: 1000 * 20,
  queryParams: {
    meetingId,
    startTime: startDate(),
    offset: 0,
    limit: 1000,
    sort: 'startTimestamp,desc'
  },
  cacheFunc: () => []
}).then((result: RestResult<MeetingResponse>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return new MeetingResponse()
    }
  }
})

export const getMeetingsByTimeInterval = (startTime: Date, endTime: Date, offset = 0): Promise<MeetingResponse> =>
  restModule.get({
    url: '/reportings/meetings/',
    pathParams: {},
    queryParams: { startTime, endTime, offset },
    timeout: 1000 * 20,
    cacheFunc: () => undefined
  })
  .then((result: RestResult<MeetingResponse>) => result.data)

// Get all meetings by iterating through all pagination offsets
export const getAllMeetingsByTimeInterval =
  (startTime: Date, endTime: Date, offset = 0, accu: Array<Meeting> = []): Promise<Array<Meeting>> =>
    getMeetingsByTimeInterval(startTime, endTime, offset)
    .then((response: MeetingResponse) => {
      const { items, limit } = response
      const count = items.length
      if (count < limit) {
        return accu.concat(items)
      }
      return getAllMeetingsByTimeInterval(startTime, endTime, ++offset, accu.concat(items))
    })

export const getMeetingsById: (id: string) => Promise<Meeting> = (id: string): Promise<Meeting> => restModule.get({
  url: '/reportings/meetings/:id',
  timeout: 1000 * 20,
  pathParams: { id },
  queryParams: {
  },
  cacheFunc: () => []
}).then((result: RestResult<Meeting>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return new Meeting()
    }
  }
})

export const getParticipantsByMeetingId: (id: string) => Promise<Participant[]> = (id: string): Promise<Participant[]> => restModule.get({
  url: '/reportings/meetings/:id/participants',
  pathParams: { id },
  timeout: 1000 * 20,
  queryParams: {
  },
  cacheFunc: () => []
}).then((result: RestResult<Participant[]>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return []
    }
  }
})

export const getAddonsByMeetingId: (id: string) => Promise<AddOn[]> = (id: string): Promise<AddOn[]> => restModule.get({
  url: '/reportings/meetings/:id/add-ons',
  pathParams: { id },
  timeout: 1000 * 20,
  queryParams: {
  },
  cacheFunc: () => []
}).then((result: RestResult<AddOn[]>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return []
    }
  }
})
