













































import { Ref, Component } from 'vue-property-decorator'
import { deleteNews, News, getNews } from '@/customer-portal'
import { SfList } from '@/components'
import { SfContentWrapper, SfStringCell } from '@starface/sf-components/src/components/'
import { SfTable } from '@starface/sf-components/types/components'
import moment from 'moment'
import { ListedNews } from '@/customer-portal/news/entities/news'

@Component({
  components: { SfContentWrapper, SfStringCell }
})
export default class NewsList extends SfList {

  @Ref()
  private readonly newsTable!: SfTable

  protected entityType = 'news'

  protected deleteRestFunction = async (id: string) => deleteNews(id).then(() => this.loadNews())

  private news: ListedNews[] = []

  private loadNews(): void {
    this.newsTable.setLoading()
    getNews().then(allNews => {
      this.news = allNews.map((news) => {
        const startMoment = moment(news.startTimestamp)
        const endMoment = moment(news.endTimestamp)
        const hasNotStarted = startMoment.isAfter(moment())
        const hasNotEnded = endMoment.isAfter(moment())
        return {
          id: news.id,
          name: news.name,
          startDate: startMoment.format('YYYY-MM-DD'),
          endDate: endMoment.format('YYYY-MM-DD'),
          icon: news.icon,
          status: [{
            icon: hasNotStarted ? 'large calendar plus' : hasNotEnded ? 'large calendar check' : 'large calendar minus',
            value: hasNotStarted ? 'not active yet' : hasNotEnded ? 'active' : 'was active',
            color: hasNotStarted ? 'yellow' : hasNotEnded ? 'green' : 'grey',
            tooltip: hasNotStarted ? this.$t('news.label.nya') : hasNotEnded ? this.$t('news.label.active') : this.$t('news.label.wasActive')
          }],

          url: news.url,
          channels: news.channels
        }
      })
      this.newsTable.setReady()
      this.isMounted = true
    }).catch()
  }

  public mounted(): void {
    this.loadNews()
  }

  protected get selectedEntities(): News[] {
    if (this.isMounted) {
      return this.newsTable.getSelectedRows() as News[]
    }
    return []
  }

  protected commaSeparatedNames(): string {
    return this.selectedEntities.map(news => news.name).join(',')
  }

}
