import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { FileOverview ,FileList, FileEditor } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class FileRoutes implements RouteDefinition {
    public getRouteConfig(): RouteConfig {
        return {
            path: '/file',
            component: FileOverview,
            children: [
                {
                    path: ':id',
                    component: FileEditor,
                    props: true
                },
                {
                    path: '/',
                    component: FileList,
                    meta: { entity: 'file' }
                }
            ],
            props: true
        }
    }
}
