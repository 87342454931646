import { Container } from 'typedi'
import { NewsRoutes } from './services/news-routes'

export { createNews, getNews, getActiveNews, updateNews, deleteNews } from './services/news-rest'


Container.import([
  NewsRoutes
])

export { default as News } from './entities/news'

export { default as NewsList } from './news-list.vue'
export { default as NewsEditor } from './news-editor.vue'
export { default as NewsOverview } from './news-overview.vue'

