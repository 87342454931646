































import { Component, Vue } from 'vue-property-decorator'
import { SfForm, SfModal } from '@starface/sf-components/types/components'
import ChangePasswordDialogue from '@/customer-portal/account/account-change-password-dialogue.vue'
import SfSideMenu from '@starface/sf-components/src/components/site/sf-side-menu.vue'
import SfSideMenuItem from '@starface/sf-components/src/components/site/sf-side-menu-item.vue'
import SfMenuItem from '@starface/sf-components/src/components/site/sf-menu-item.vue'

@Component({
    components: { SfMenuItem, SfSideMenuItem, SfSideMenu, ChangePasswordDialogue }
  })
  export default class App extends Vue {

    // @ts-ignore
    public $refs: {
      toastForm: SfForm;
      toastDialog: SfModal;
      changePasswordDialogue: SfModal;
    }

    private changePasswordDialogue!: SfModal

    private logout(): void {
      this.$rest.removeCookie()
      window.location.replace('/login.html')
    }

    private toggleChangePasswordDialog(): void {
      this.changePasswordDialogue.toggleHidden()
    }

    private get hasAtLeastDevRole() {
      return this.$rest.hasAdminOrGivenRole('ROLE_DEV')
    }

    private get hasAtLeastReportingRole() {
      return this.$rest.hasAdminOrGivenRole('ROLE_REPORTING')
    }

    private get hasAdminRole() {
      return this.$rest.hasAdminOrGivenRole('ROLE_ADMIN')
    }

    private mounted(): void {
      this.changePasswordDialogue = this.$refs.changePasswordDialogue
      if (!(performance.getEntriesByType('navigation')[0].toJSON().type === 'reload')) {
        this.$toast.success('security.loginToastMessage')
      }
    }
  }
