import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { PbxOverview, PbxView, PbxSearch } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class PbxRoutes implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/pbx',
      component: PbxOverview,
      props: true,
      children: [
        {
          path: ':id',
          component: PbxView,
          props: true
        },
        {
          path: '/',
          component: PbxSearch,
          meta: { entity: 'pbx' }
        }
      ],
    }
  }
}
