









































import { Component, Prop, Ref } from 'vue-property-decorator'
import { Admin, getAdmin, Role, roleValues } from '@/customer-portal'
import { SfEditor } from '@/components'
import { updateAdmin, createAdmin, deleteAdmin } from '@/customer-portal/admin/services/admin-rest'
import { SfForm } from '@starface/sf-components/types/components'
import SfPasswordField from '@starface/sf-components/types/components/form/fields/sf-field-password.vue'
import SfMultiDropdownField from '@starface/sf-components/src/components/form/fields/sf-field-dropdown-multi.vue'

@Component({ components: { SfMultiDropdownField } })
export default class AdminEditor extends SfEditor {

  @Ref()
  public readonly adminForm!: SfForm

  @Ref()
  public readonly password!: SfPasswordField

  @Ref()
  public readonly currentRole!: SfMultiDropdownField

  @Prop({ type: String, required: true })
  public id!: string

  public admin: Admin = new Admin()

  protected entityType = 'admin'

  private adminId: string = this.$rest.getAdminId

  private roles: Array<{key: string; value: Role}> = roleValues.map(role => ({ key: role, value: role }))

  private selectedRoles: Array<{key: string; value: string}> = []

  protected updateRestFunction: ((id: string, data: any) => Promise<string>) | undefined
    = updateAdmin

  protected createRestFunction: ((data: any) => Promise<string>) | undefined
    = createAdmin

  protected deleteRestFunction: ((id: string) => Promise<string>) | undefined
    = deleteAdmin

  protected getData(): any | undefined {
    if (!this.adminForm.hasErrors()) {
      const formAdmin: Admin = this.adminForm.getData<Admin>()
      if (this.id === 'new') {
        return {
          firstName: formAdmin.firstName,
          lastName: formAdmin.lastName,
          email: formAdmin.email,
          password: this.password.getValue(),
          // @ts-ignore
          roles: Array.from(formAdmin.roles.map((keyValue: {key: string; value: string}) => keyValue.value))
        }
      }
      else {
        return {
          firstName: formAdmin.firstName,
          lastName: formAdmin.lastName,
          email: formAdmin.email,
          // @ts-ignore
          roles: Array.from(formAdmin.roles.map((keyValue: {key: string; value: string}) => keyValue.value))
        }
      }
    }
    return undefined
  }

  public get computedName(): string {
    if (this.isMounted) {
      return this.adminForm.getData<Admin>().firstName + ' ' + this.adminForm.getData<Admin>().lastName
    } else {
      return ''
    }
  }

  private loadData(): void {
    this.adminForm.setLoading()
    getAdmin(this.id).then((restAdmin: any) => {
      this.admin = restAdmin
      const restRoles: Array<{key: string; value: string}> = this.roles.map((keyValue: {key: string; value: string}) => {
        if ((restAdmin.roles as string[]).includes(keyValue.key)) {
          return { ...keyValue, selected: true }
        }
        return keyValue
      })
      this.selectedRoles.push(...restRoles)
      this.adminForm.setReady()
    }).catch()
  }

  public mounted(): void {
    this.adminForm.clearForm()
    if (! this.isNew(this.id)) {
      this.loadData()
    } else {
      this.selectedRoles = Array.from(this.roles)
    }
    this.isMounted = true
  }
}
