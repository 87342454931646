import BaseRestEntity from '@/common/entities/base-rest-entity'
import moment from 'moment'

export default class News extends BaseRestEntity {
    public startTimestamp = moment().unix()
    public endTimestamp = moment().unix()
    public icon: Icon = 'info'
    public headlineText = new LocaleText()
    public descriptionText = new LocaleText()
    public linkText = new LocaleText()
    public internationalizedLinks = new LocaleText()
    public url = ''
    public theme = ''
    public channels: string[] = []
    public videoId?: string = undefined
}

export class NewsLanguageSplit {
    public name = ''
    public startDate = ''
    public endDate = ''
    public icon: { key: Icon, value: string } = { key: 'info', value: '' }
    public headlineTextDE = ''
    public headlineTextFR = ''
    public headlineTextEN = ''
    public descriptionTextDE = ''
    public descriptionTextFR = ''
    public descriptionTextEN = ''
    public linkTextDE = ''
    public linkTextFR = ''
    public linkTextEN = ''
    public linkDE = ''
    public linkEN = ''
    public linkFR = ''
    public url = ''
    public theme: { key: Theme, value: string } = { key: '', value: 'None' }
    public channels: { key: Channel, value: string }[] = [{ key: 'sf-app', value: '' }]
    public video: { key: string, value: string} = { key: '', value: '' }
}

export type Icon = 'info' | 'help' | 'exclamation triangle'

export type Channel = 'sf-app' | 'home'

export type Theme = '' | 'xmas'

export type ListedNews = {
    name: string
    id: string,
    startDate: string,
    endDate: string,
    icon: Icon,
    status: any,
    url: string,
    channels: string[]
}

class LocaleText {
    DE = ''
    FR = ''
    EN = ''
}
