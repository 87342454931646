




























































import { Ref, Component, Prop, Vue } from 'vue-property-decorator'
import SfMultiDropdownField from '@starface/sf-components/src/components/form/fields/sf-field-dropdown-multi.vue'
import { getMeetingsByMeetingId } from '@/customer-portal/meeting/services/meeting-rest'
import Meeting from '@/customer-portal/meeting/entities/meeting'
import MeetingResponse from '@/customer-portal/meeting/entities/meeting-response'
import {
  SfDateCell,
} from '@starface/sf-components/src/components/'
import SfTable, { RowFunction } from '@starface/sf-components/types/components/table/sf-table.vue'
import moment from 'moment'

@Component({ components: { SfMultiDropdownField, SfDateCell } })
export default class MeetingSearch extends Vue {

  @Ref()
  private readonly searchField: HTMLInputElement

  @Ref()
  private readonly meetingTable: SfTable

  private meetings: Meeting[] = []

  private awaitRestResponse = false
  private noMeetingFound = false

  private searchedMeeting = ''

  private rowFunctions: RowFunction[] = [
    {
      name: 'meetingDetailView', icon: 'eye', color: 'orange',
      onclick: (index, row, item: any) => {
        this.goToMeetingDetailView(item.id)
      }
      }
    ]

  public goToMeetingDetailView(id: string): void {
    this.$router.push(`${this.$route.path}/${id}`).catch()
  }

  private newSearch(): void {
    this.meetings = []
    this.searchedMeeting = ''
    this.noMeetingFound = false
    this.$nextTick(() => this.searchField.focus())
  }

  public search(): void {
    this.awaitRestResponse = true
    this.meetingTable.setLoading()
    this.$router.push(`${this.$route.path}?meetingId=${this.searchedMeeting}`).catch()

    this.loadMeetings(this.searchedMeeting)
  }

  private loadMeetings(meetingId: string): void {
      getMeetingsByMeetingId(meetingId).then((meetings: MeetingResponse) => {
        this.noMeetingFound = meetings.items.length === 0
        this.meetings = meetings.items.map(meeting => {

          return { ...meeting,
            duration: this.duration(meeting.startTimestamp, meeting.endTimestamp),
            startTimestamp: this.formatDate(meeting.startTimestamp),
            endTimestamp: this.formatDate(meeting.endTimestamp),
            pbxLink: {
              url: '/#/pbx/' + meeting.pbxId,
              text: meeting.pbxId
            },
          }
        })
      }).finally(() => {
        this.awaitRestResponse = false
        this.meetingTable.setReady()
      }).catch(() => {
        this.$toast.error('meeting.cant-request-meeting-data')
      })
    }

  private formatDate(date: string): string {
    const formatedDate: moment.Moment = moment(date)
    formatedDate.local()
    return formatedDate.format('dd, DD-MM, HH:mm:ss')
  }


  private duration(start: string, end: string): string {
    return moment.utc(moment(end).diff(moment(start))).format('HH:mm:ss')
  }

  private mounted(): void {
    if (this.$route.query.meetingId) {
      this.loadMeetings(this.$route.query.meetingId as string)
    }
  }
}
