import { Container } from 'typedi'
import { PbxRoutes } from './services/pbx-routes'

Container.import([
  PbxRoutes
])

export { default as PbxOverview } from './pbx-overview.vue'
export { default as PbxSearch } from './pbx-search.vue'
export { default as PbxView } from './pbx-view.vue'
