import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from './services/'
import Token from './customer-portal/token.vue'

@Service({ id: routeToken, multiple: true })
export class HomeRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/',
      component: Token,
      props: true
    }
  }
}
