







































import { Ref, Component, Prop, Vue } from 'vue-property-decorator'
import SfMultiDropdownField from '@starface/sf-components/src/components/form/fields/sf-field-dropdown-multi.vue'
import { getPbxByLicenseKey } from '@/customer-portal/pbx/services/pbx-rest'
import { Pbx } from '@/customer-portal/pbx/entities/pbx'
import PbxResponse from '@/customer-portal/pbx/entities/pbx-response'
import { SfDateCell } from '@starface/sf-components/src/components/'
import SfTable, { RowFunction } from '@starface/sf-components/types/components/table/sf-table.vue'

interface PbxView {
  id: string;
  serverLicenseKey: string;
  enabled: boolean;
  registered: boolean;
}

@Component({ components: { SfMultiDropdownField, SfDateCell } })
export default class PbxSearch extends Vue {

  @Ref()
  private readonly searchField: HTMLInputElement

  @Ref()
  private readonly pbxTable: SfTable

  private pbxViews: PbxView[] = []
  private noPbxFound = false
  private searchedLicenseKey = ''
  private awaitRestResponse = false

  public goToPbxDetailView(id: string): void {
    this.$router.push(`${this.$route.path}/${id}`).catch()
  }

  public async search(): Promise<void> {
    this.awaitRestResponse = true
    this.pbxTable.setLoading()
    this.$router
      .push(`${this.$route.path}?serverLicenseKey=${this.searchedLicenseKey}`)
      .catch()

    await this.loadPbx(this.searchedLicenseKey)
  }

  private async loadPbx(serverLicenseKey: string): Promise<void> {
    try {
      const pbxResponse: PbxResponse = await getPbxByLicenseKey(serverLicenseKey)
      this.noPbxFound = pbxResponse.items.length === 0
      this.pbxViews = pbxResponse.items.map(pbx => ({ ...pbx, registered: pbx.publicKeys.length > 0 }))
    } catch (e) {
      this.noPbxFound = true
      this.$toast.error('pbx.cant-request-data')
    } finally {
      this.awaitRestResponse = false
      this.pbxTable.setReady()
    }
  }

  private rowFunctions: RowFunction[] = [
    {
      name: 'pbxDefailtView',
      icon: 'eye',
      color: 'orange',
      onclick: (index, row, item: any) => {
        this.goToPbxDetailView(item.id)
      }
    }
  ]

  private mounted(): void {
    if (this.$route.query.serverLicenseKey) {
      this.loadPbx(this.$route.query.serverLicenseKey as string).catch()
    }
  }
}
