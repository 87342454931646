import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import { News } from '@/customer-portal'

type ItemsContainer<T> = { items: T[] }
const restModule: RestModule = getModule(RestModule, store)

export const createNews = (news: News): Promise<News> => restModule.post({
    url: '/news',
    pathParams: {},
    body: news
}).then((result: RestResult<News>) => result.data)

export const getNews = (): Promise<News[]> => restModule.get({
    url: '/news',
    pathParams: {},
    queryParams: {},
    cacheFunc: () => []
}).then((result: RestResult<ItemsContainer<News>>) => result.data.items)

export const getNewsById = (id: string): Promise<News> => restModule.get({
    url: '/news/:newsId',
    pathParams: { newsId: id },
    queryParams: {},
    cacheFunc: () => []
}).then((result: RestResult<News>) => result.data)

export const getActiveNews = (channel?: string): Promise<News> => restModule.get({
    url: '/news?active=true' + channel ? '&channel=:channel' : '',
    pathParams: channel ? { channel: channel } : {},
    queryParams: {},
    cacheFunc: () => undefined
}).then((result: RestResult<ItemsContainer<News>>) => result.data.items[1])

export const updateNews = (id: string, news: News): Promise<News> => restModule.put({
    url: '/news/:newsId',
    pathParams: { newsId: id },
    body: news
}).then((result: RestResult<News>) => result.data)

export const deleteNews = (id: string): Promise<void> => restModule.delete({
    url: '/news/:newsId',
    pathParams: { newsId: id }
})
