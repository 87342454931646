




























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import VueMarkdown from 'vue-markdown-v2'
import { SfForm } from '@starface/sf-components/types/components'
import {
  getMeetingByMeetingId,
  updateMeetingByMeetingId
} from '@/customer-portal/internal-meeting/services/meeting-rest'
import { SfEditor } from '@/components'
import { BaseRestEntity } from '@/common'

// @ts-ignore
@Component({ components: { VueMarkdown } })
export default class MeetingEditor extends SfEditor {
  protected createRestFunction: ((data: any) => Promise<any>) | undefined
  protected deleteRestFunction: ((id: string) => Promise<any>) | undefined

  @Prop({ type: String, required: true })
  public id!: string

  @Ref()
  public readonly internalMeetingForm!: SfForm

  public meeting = { meeting: 'no-data' }

  private get meetingIdForHeader(): string {
    return `(${this.id})`
  }

  private backToMeetingSearch(): void {
    this.$router.push('/internal/meetings').catch()
  }

  private mounted(): void {
    this.internalMeetingForm.setLoading()
    getMeetingByMeetingId(this.id)
        .then(meetingResult => this.meeting = { meeting: JSON.stringify(meetingResult.data, undefined, 1) })
        .catch((e) => {
          console.error(e)
          this.$toast.error('meeting.cant-request-meeting-data')
        })
        .finally(() => this.internalMeetingForm.setReady())
  }

  protected entityType = 'meeting'
  protected updateRestFunction: ((id: string, data: any) => Promise<any>) | undefined = updateMeetingByMeetingId

  protected getData(): BaseRestEntity | undefined {
    return JSON.parse(this.internalMeetingForm.getData<{ meeting: string}>().meeting)
  }

}
