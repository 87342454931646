import { Container } from 'typedi'
import { FileRoutes } from './services/file-routes'

export { deleteFile, createFile, getFiles, getFileById, getVideoStreamUrlForFile } from './services/file-rest'


Container.import([
    FileRoutes
])

export { default as VideoFile } from './entities/file'

export { default as FileList } from './file-list.vue'
export { default as FileEditor } from './file-editor.vue'
export { default as FileOverview } from './file-overview.vue'

