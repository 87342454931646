import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import { ParticipantStatus } from '@/customer-portal'

const restModule: RestModule = getModule(RestModule, store)

export const getParticipantStatuses = (meetingSessionId: string) =>
  restModule.get({
    url: '/reportings/participantStatus/:meetingSessionId',
    pathParams: { meetingSessionId },
    queryParams: {},
    cacheFunc: () => undefined,
    timeout: 15000
  })
  .then((result: RestResult<ParticipantStatus[]>) => {
    return result.data
  })

