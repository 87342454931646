import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import { RequestStatus, RestModule, RestResult } from '@/services'
import { Admin, Survey } from '@/customer-portal'

const restModule: RestModule = getModule(RestModule, store)

export const getSurvey: () => Promise<Survey[]> = (): Promise<Survey[]> => restModule.get({
  url: '/reportings/satisfactionRecords',
  timeout: 1000 * 20,
  pathParams: {},
  queryParams: {
    limit: 1000, // higher limits would overshoot maximum size -> add pagination
    sort: 'submissionTime,DESC'
  },
  cacheFunc: () => []
}).then((result: RestResult<Survey[]>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return []
    }
  }
})
