import './class-component-hooks'
import Vue from 'vue'
import Router, { RouteConfig, RouterOptions } from 'vue-router'
import { Container, Token } from 'typedi'

Vue.use(Router)

export const routeToken: Token<RouteDefinition> = new Token<RouteDefinition>('RouteDefinition')

export abstract class RouteDefinition {
  public abstract getRouteConfig(): RouteConfig;
}

export class RouterService {

  public router!: Router

  public createRouter(): Router {
    const routeDefinitions: RouteConfig[] = Container.getMany(routeToken)
      .reduce((routes: RouteConfig[], module) => {
        routes.push(module.getRouteConfig())
        return routes
      }, [])
    const routerOptions: RouterOptions = { routes: routeDefinitions }
    this.router = new Router(routerOptions)
    return this.router
  }

  public getRouter(): Router {
      return this.router
  }
}

export const routerService: RouterService = new RouterService()
