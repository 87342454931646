import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'

const restModule: RestModule = getModule(RestModule, store)

export const changePassword: (currentPassword: string, newPassword: string) => Promise<string> = (
  currentPassword: string, newPassword: string): Promise<string> => restModule.post({
  url: '/admins/:adminId/changePassword',
  pathParams: {},
  body: {
    currentPassword,
    newPassword
  }
}).then((result: RestResult<string>) => {
  return result.data
})
