import { Container } from 'typedi'
import { AccountRoute } from './services/account-route'

Container.import([
  AccountRoute
])

export { default as UserProfile } from './account-editor.vue'
export { default as ChangePasswordDialogue } from './account-change-password-dialogue.vue'

export type Role = (typeof roleValues[number])
export const roleValues = ['ROLE_ADMIN', 'ROLE_DEV', 'ROLE_REPORTING'] as const
