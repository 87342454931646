import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '../../../services'
import { MeetingOverview, MeetingSearch, MeetingView } from '../.'

@Service({ id: routeToken, multiple: true })
export class MeetingRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/meetings',
      component: MeetingOverview,
      children: [
        {
          path: ':id',
          component: MeetingView,
          props: true
        },
        {
          path: '/',
          component: MeetingSearch,
          meta: { entity: 'meeting' }
        }
        ],
      props: true
    }
  }
}
