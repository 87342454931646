import { Container } from 'typedi'
import { SurveyRoute } from './services/survey-route'

Container.import([
  SurveyRoute
])

export { default as Survey } from './entities/survey'
export { default as SurveyList } from './survey-list.vue'
export { default as SurveyOverview } from './survey-overview.vue'
