























import Vue from 'vue'
import { Component, Prop, Ref } from 'vue-property-decorator'
import axios from 'axios'

@Component({})
export default class FileEditor extends Vue {

  @Prop({ type: String, required: true })
  public id!: string

  private file?: File

  @Ref()
  private fileForm: HTMLFormElement

  private loading = false

  @Ref()
  private readonly videoFileInput: HTMLInputElement

  private handleFileChange(event: InputEvent) {
    this.file = (event.target as HTMLInputElement).files?.item(0) ?? undefined
  }

  private cancel = () => this.$router.back()

  private async createNewFile(event: Event) {
    event.preventDefault()
    if (this.file) {
      this.loading = true
      const data = new FormData()
      data.append('newFile', this.file, this.file.name)
      return axios
          .post('api/files', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .finally(() => {
            this.fileForm.reset()
            this.file = undefined
            this.loading = false
          })
          .then(() => this.$toast.success(this.$t('file.toast.createSuccessful') || ''))
          .catch(e => {
            console.error('Failed to upload File', e)
            this.$toast.error(this.$t('file.toast.createFailed') || '')
          })
    } else {
      this.$toast.warning('No file selected!')
    }
  }

}
