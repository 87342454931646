
















































  import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
  import VueMarkdown from 'vue-markdown-v2'
  import { Pbx, PublicKey, emptyPbx, Configuration } from '@/customer-portal/pbx/entities/pbx'
  import { getPbxById, updatePbX } from '@/customer-portal/pbx/services/pbx-rest'
  import { RawLocation } from 'vue-router/types/router'
  import { formatDate } from '@/common'
  import { SfForm, SfModal } from '@starface/sf-components/types/components'

  // @ts-ignore
  @Component({ components: { VueMarkdown } })
  export default class PbxView extends Vue {

    @Prop({ type: String, required: true })
    public id!: string

    @Ref()
    private readonly editPbxDialog!: SfModal

    @Ref()
    private readonly editPbxForm!: SfForm

    private planChoices: { key: Pbx['configuration']['paymentPlan'], value: string }[] = [
      { key: 'FREEMIUM', value: 'FREEMIUM' },
      { key: 'FULL_PLAN', value: 'FULL_PLAN' }
    ]

    private loaded = false
    private updating = false

    private pbx: Pbx = emptyPbx()

    private get renderDetails(): string {
      if (!this.loaded) {
        return 'Loading PBX data...'
      }

      return `
| ${this.t('pbx.view.description')}    | ${this.t('pbx.view.value')}  |
| ------------------------------------ | ---------------------------- |
| ${this.t('pbx.view.detail.id')}      | ${this.pbx.id}               |
| ${this.t('pbx.view.detail.key')}     | ${this.pbx.serverLicenseKey} |
| ${this.t('pbx.view.detail.enabled')} | ${this.pbx.enabled}          |
      `
    }

    private get renderConfiguration(): string {
      return `
| ${this.t('pbx.view.description')}                | ${this.t('pbx.view.value')}                   |
| ------------------------------------------------ | --------------------------------------------- |
| ${this.t('pbx.view.config.description')}        | ${this.pbx.configuration.description}         |
| ${this.t('pbx.view.config.allowedAddOns')}      | ${this.pbx.configuration.allowedAddOns.join(', ')}        |
| ${this.t('pbx.view.config.maxMeetingDuration')} | ${this.pbx.configuration.maxMeetingDuration}        |
| ${this.t('pbx.view.config.maxMeetingSize')}     | ${this.pbx.configuration.maxMeetingSize} |
| ${this.t('pbx.view.config.maxMeetingRooms')}     | ${this.pbx.configuration.maxRoomsByUser} |
| ${this.t('pbx.view.config.paymentPlan')}        | ${this.pbx.configuration.paymentPlan} |
| ${this.t('pbx.view.config.schedulingEnabled')}  | ${this.pbx.configuration.schedulingEnabled} |
      `
    }

    private get renderPublicKeys(): string {
      return this.pbx.publicKeys
        .map(this.renderPublicKey)
        .reduce((previous, current) => previous + current, '')
    }

    private renderPublicKey(key: PublicKey): string {
      return `
| ${this.t('pbx.view.description')}                | ${this.t('pbx.view.value')}               |
| ------------------------------------------------ | ----------------------------------------- |
| ${this.t('pbx.view.publicKey.algorithm')}        | ${key.algorithm}                          |
| ${this.t('pbx.view.publicKey.publicSigningKey')} | ${key.base64EncodedPublicSigningKey}      |
| ${this.t('pbx.view.publicKey.lastUsed')}         | ${formatDate(key.metadata.lastUsed)}      |

      `
    }

    private backToPbxSearch(): void {
      if (this.pbx && this.pbx.serverLicenseKey) {
        const url: RawLocation = {
          path: '/pbx',
          query: { serverLicenseKey: this.pbx.serverLicenseKey }
        }
        this.$router.push(url).catch()
      } else {
        this.$router.push('/pbx').catch()
      }
    }

    private toggleEditDialog(): void {
      this.editPbxDialog.toggleHidden()
    }

    /** Localise the argument string */
    private t(key: string): string {
      const translation: string | undefined = this.$i18n.translate(key)
      return translation ? translation : ''
    }

    private get pbxIdForHeader(): string {
      if (this.loaded) {
        return `(${this.pbx.id})`
      }
      return ''
    }

    private async saveAndClose(): Promise<void> {
      this.updating = true

      try {
        const configuration = this.editPbxForm.getData<Pbx['configuration']>()
        await updatePbX(this.id, {
          configuration: {
            ...configuration,
            // @ts-ignore
            paymentPlan: configuration.paymentPlan.value
          },
          serverLicenseKey: this.pbx.serverLicenseKey
        })

        await this.loadData()

        this.toggleEditDialog()
      } catch (e) {
        console.error(e)
      } finally {
        this.updating = false
      }
    }

    private get pbxFormData() {
      const formData : Omit<Configuration, 'paymentPlan'>
          & {paymentPlan: {key: string, value: string} | string} = this.pbx.configuration
      formData.paymentPlan = { key: this.pbx.configuration.paymentPlan, value: this.pbx.configuration.paymentPlan }
      return formData
    }

    private async loadData(): Promise<void> {
      try {
        const pbxResponse = await getPbxById(this.id)
        this.pbx = pbxResponse.items[0]
      } catch (e) {
        console.error(e)
        this.$toast.error('pbx.cant-request-pbx-data')
      } finally {
        this.loaded = true
      }
    }

    private mounted(): void {
      this.loadData()
    }
  }
