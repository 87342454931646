















import { Ref, Component, Vue } from 'vue-property-decorator'
import { SfForm, SfPasswordField, SfStringField } from '@starface/sf-components/types/components'
import { ErrorResponse, ResponseStatus, RestModule, store } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { changePassword } from './services/account-rest'

@Component({})
export default class ChangePasswordDialogue extends Vue {

  private restModule: RestModule = getModule(RestModule, store)

  @Ref()
  private readonly currentPassword!: SfStringField

  @Ref()
  private readonly changePasswordForm!: SfForm

  @Ref()
  private readonly passwordField!: SfPasswordField

  private inProcess = false

  public saveAndClose(): void {
    if (!this.changePasswordForm.hasErrors()) {
      const currentPassword: string = this.changePasswordForm.getFieldData('currentPassword')
      const newPassword: string = this.passwordField.getValue()
      this.inProcess = true
      this.changePassword(currentPassword, newPassword)
        .then(() => {
          this.emitCloseEvent()
        })
        .catch(error => {
          let message: string | undefined
          if (error.response) {
            const response: ErrorResponse = error.response

            switch (response.status) {
              case ResponseStatus.BAD_REQUEST: {
                for (const validationError of response.data.validationErrors) {
                  this.changePasswordForm.addValidationErrorFromBackend(validationError.jsonPath, 'validation.' + validationError.errorCode)
                }
                message = this.$i18n.translate('account.toast.saveFailed', {})
                break
              }
              case ResponseStatus.FORBIDDEN: {
                this.changePasswordForm.addValidationErrorFromBackend('currentPassword', 'account.toast.passwordChangeFailed')
                message = this.$i18n.translate('account.toast.passwordChangeFailed', {})
                break
              }
              default: {
                message = this.$i18n.translate('account.toast.saveFailed', {})
              }
            }
          }

          if (message) {
            this.$toast.error(message)
          }
        })
        .finally(() => this.inProcess = false)
        .catch()
    }
  }

  public emitCloseEvent(): void {
    this.$emit('close')
    this.changePasswordForm.clearForm()
  }

  private async changePassword(currentPassword: string, newPassword: string): Promise<any> {
    const accountId: string = this.restModule.getAdminId

    return changePassword(currentPassword, newPassword)
  }
}
