import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'

const restModule: RestModule = getModule(RestModule, store)

export const getActorData = (actorType: string, actorId: string): Promise<RestResult<string>> => restModule.put({
    url: '/actors/:actorType/:actorId/method/getActorState',
    pathParams: { actorType, actorId },
    body: {}
})

export const resetActor = (actorType: string, actorId: string): Promise<void> => restModule.put({
    url: '/actors/:actorType/:actorId/method/reset',
    pathParams: { actorType, actorId },
    body: {}
}).then()
