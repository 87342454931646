import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '../../../services'
import { UserProfile } from '../..'

@Service({ id: routeToken, multiple: true })
export class AccountRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/profile',
      component: UserProfile,
    }
  }
}
