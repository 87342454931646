import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '../../../services'
import { Overview } from '../.'

@Service({ id: routeToken, multiple: true })
export class OverviewRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/overview',
      component: Overview,
    }
  }
}
