import Vue from 'vue'
import Router from 'vue-router'
import vuexI18n from 'vuex-i18n'
import VueCookies from 'vue-cookies-ts'
import { Container } from 'typedi'
import 'reflect-metadata'
import { sync } from 'vuex-router-sync'

import SfComponents from '@starface/sf-components'
import '@starface/sf-components/dist/sf-components.css'
import 'markdown-it-icons/dist/index.css'
import '@starface/fomantic-theme-starface/dist/semantic.min.css'
import translationsEn from '../public/assets/i18n/en.json'

import { store } from '@/services/store/store'
import { routerService, registerRouteRules, RestModule } from './services'
import { App } from './customer-portal'
import { HomeRoute } from './global-route'
import { getModule } from 'vuex-module-decorators'
import VueApexCharts from 'vue-apexcharts'
import { SfToastContainer } from '@starface/sf-components/types/components'

declare module 'vue/types/vue' {

  interface Vue {
    $rest: RestModule;
    $toast: SfToastContainer;
  }
}

const NEON_APP: any = Vue

NEON_APP.config.productionTip = false

NEON_APP.use(VueCookies)
NEON_APP.use(Router)
NEON_APP.use(VueApexCharts)
NEON_APP.component('apexchart', VueApexCharts)
NEON_APP.use(SfComponents, { store, i18n: vuexI18n.plugin })

// TODO: What is wrong here?! Declarations for Vue Interface exists but not recognized from compiler? Workaround (cast to any) is ugly af...
NEON_APP.i18n.add('en', translationsEn)
NEON_APP.i18n.set('en')

NEON_APP.prototype.$rest = getModule(RestModule, store)

Container.import([
  HomeRoute
])

const router: Router = routerService.createRouter()
registerRouteRules(router)

const unsync = sync(store, router)

// @ts-ignore
window.store = store

const vueInstance: Vue = new NEON_APP({
  store,
  router,
  render: (h: any) => h(App),
}).$mount('#app')
Container.set(NEON_APP, vueInstance)

// vueInstance.$rest.configureBackendUrls().then(() => {
// }).catch();
