


















































import { Component, Ref } from 'vue-property-decorator'
import { Survey } from '.'
import { SfList } from '@/components'
import moment from 'moment'
import {
  SfContentWrapper,
  SfStringCell,
  SfDateCell,
  SfLabelCell
} from '@starface/sf-components/src/components/'
import { SfTable } from '@starface/sf-components/types/components'
import { getSurvey } from '@/customer-portal/survey/services/survey-rest'
import SfModal from '@starface/sf-components/types/components/site/sf-modal.vue'

@Component({
  components: { SfContentWrapper, SfStringCell, SfDateCell, SfLabelCell }
})
export default class SurveyList extends SfList {

  @Ref()
  private readonly feedbackView!: SfModal

  protected entityType = 'survey'

  private surveys: Survey[] = []

  private selectedFeedbackTime = ''
  private selectedFeedback = ''
  private selectedOverallRating = 0
  private selectedFeedbackMeetingId = ''

  protected deleteRestFunction: ((id: string) => Promise<string>) | undefined = undefined

  @Ref()
  private readonly surveyTable!: SfTable

  public openFeedbackView(survey: any): void {
    this.selectedFeedbackTime = moment(survey.submissionTime).format('LLLL')
    this.selectedFeedback = survey.feedback
    this.selectedFeedbackMeetingId = survey.meetingLink.text !== '' ? survey.meetingLink.text : '-'
    this.selectedOverallRating = survey.overallRating[0].value

    this.feedbackView.toggleHidden()
  }

  public emitCloseEvent(): void {
    this.feedbackView.toggleHidden()
  }

  public mounted(): void {
    this.surveyTable.setLoading()
    getSurvey().then((surveys: any) => {

      this.surveys = surveys.items.map((loadedSurvey: Survey) => {
        const nps: number = loadedSurvey.overallRating
        return {
          meetingLink: {
            url: '/#/meetings?meetingId=' + loadedSurvey.meetingId,
            text: loadedSurvey.meetingId
          },
          feedback: loadedSurvey.feedback,

          overallRating: [{
            icon: 'heart',
            value: `${nps} / 10`,
            color: nps > 0 ? nps > 6 ? nps > 8 ? 'green' : 'yellow' : 'red' : 'grey',
            tooltip: nps > 0 ? nps > 6 ? nps > 8 ? 'Promoter' : 'Passive' : 'Detractor' : ''
          }],

          submissionTime: loadedSurvey.submissionTime
        }
      })
      this.surveyTable.setReady()
      this.isMounted = true
    }).catch()
  }

  protected get selectedEntities(): Array<Record<string, unknown>> {
    if (this.isMounted) {
      return this.surveyTable.getSelectedRows()
    }
    return []
  }
}
