




import { Component, Vue } from 'vue-property-decorator'
import SfForm from '@starface/sf-components/src/components/form/sf-form.vue'
import { BaseRestEntity } from '@/common'
import { Error, ErrorResponse, ResponseStatus } from '@/services'

// @ts-ignore
@Component({})
export default abstract class SfEditor extends Vue {

  protected static EMPTY_CHAR = 0xFEFF
  protected static NEW_ENTITY = 'new'

  protected abstract entityType: string;

  // any should be BaseRestEntity but the compiler doesnt recognize the inheritance of child classes in this case
  protected abstract updateRestFunction: ((id: string, data: any) => Promise<any>) | undefined;
  protected abstract createRestFunction: ((data: any) => Promise<any>) | undefined;
  protected abstract deleteRestFunction: ((id: string) => Promise<any>) | undefined;

  protected isMounted = false
  protected awaitRestResponse = false

  protected abstract getData(): BaseRestEntity | undefined;

  protected save(form: SfForm, id: string, successToast = false, close = true): void {
    if ((this.updateRestFunction !== undefined) && (this.getData() !== undefined)) {
      this.awaitRestResponse = true
      this.updateRestFunction(id, this.getData()).then(() => {
        if (successToast) {
          this.$toast.success(`${this.entityType}.toast.updateSuccessful`)
        }
        if (close) {
          this.cancel()
        }
      }).finally(() => {
        this.awaitRestResponse = false
      }).catch(error => {
        this.handleError(form, error)
        this.$toast.error(`${this.entityType}.toast.updateFailed`)
      })
    }
  }

  protected create(form: SfForm, successToast = false): void {
    if ((this.createRestFunction !== undefined) && (this.getData() !== undefined)) {
      this.awaitRestResponse = true
      this.createRestFunction(this.getData()).then(() => {
        if (successToast) {
          this.$toast.success(`${this.entityType}.toast.createSuccessful`)
        }
        this.cancel()
      }).finally(() => {
        this.awaitRestResponse = false
      }).catch(error => {
        this.handleError(form, error)
        this.$toast.error(`${this.entityType}.toast.createFailed`)
      })
    }
  }

  protected saveOrCreate(form: SfForm, id: string, successToast = false): void {
    if (this.isNew(id)) {
      this.create(form, successToast)
    } else {
      this.save(form, id, successToast)
    }
  }

  protected saveWithoutClose(form: SfForm, id: string, successToast = false): void {
    this.save(form, id, successToast, false)
  }

  protected deleteWithConfirmation(form: SfForm, id: string, messageParameters: Record<string, unknown> = {}, successToast = false): void {
    if (this.isNew(id)) {
      this.cancel()
    } else {
      this.$dialog
        .confirm({
          message: `${this.entityType}.delete.confirmMessage`,
          messageParameters
        })
        .then(() => this.remove(form, id, successToast))
        .catch()
    }
  }

  protected remove(form: SfForm, id: string, successToast = false): void {
    if ((this.deleteRestFunction !== undefined) && (this.getData() !== undefined)) {
      this.awaitRestResponse = true
      this.deleteRestFunction(id).then(() => {
        this.$cache.deleteEntity({
          entityType: this.entityType,
          id
        })
        if (successToast) {
          this.$toast.success(`${this.entityType}.toast.deleteSuccessful`)
        }
        this.cancel()
      }).finally(() => {
        this.awaitRestResponse = false
      }).catch(error => {
        this.handleError(form, error)
        this.$toast.error(`${this.entityType}.toast.deleteFailed`)
      })
    }
  }

  protected isNew(id: string): boolean {
    return id === SfEditor.NEW_ENTITY
  }

  protected cancel(): void {
    this.$router.back()
  }

  private handleError(form: SfForm, error: Error): void {
    if (error.response) {
      const response: ErrorResponse = error.response
      if ((response.status === ResponseStatus.BAD_REQUEST) || (response.status === ResponseStatus.CONFLICT)) {
        for (const validationError of response.data.validationErrors) {
          // @ts-ignore
          form.addValidationErrorFromBackend(validationError.jsonPath, 'validation.' + validationError.errorCode)
        }
      }
    }
  }
}
