import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import axios, { AxiosResponse } from 'axios'
import Vue from 'vue'
import { store } from '@/services/store/store'
import { Role } from '@/customer-portal'

export enum RequestStatus {
  NORMAL,
  CACHED,
  SLOW,
  ERROR
}

export enum ResponseStatus {
  OK = 200,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  CONFLICT = 409
}

export class RestResult<T> {
  public status: RequestStatus
  public data: T

  public constructor(status: RequestStatus, data: T) {
    this.status = status
    this.data = data
  }
}

export interface ValidationError {
  errorCode: string;
  jsonPath: string;
}

export interface ErrorResponseData {
  statusCode: ResponseStatus;
  statusMessage: string;
  timestamp: string;
  validationErrors: ValidationError[];
}

export interface ErrorResponse {
  status: ResponseStatus;
  data: ErrorResponseData;
}

export interface Error {
  response: ErrorResponse;
}

@Module({ dynamic: true, store, namespaced: true, name: 'restModule' })
export class RestModule extends VuexModule {

  public restState: {
    [url: string]: {
      pending: boolean;
      retries: number;
      startTime: number;
      duration: number;
    };
  } = {}

  private adminId = ''

  private roles: Role[] = []

  private ampServiceUrl = '/api'
  private maxRetries = 3
  private refreshTimeoutAction: any

  public constructor(module: VuexModule) {
    super(module)
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common.Accept = 'application/json'
  }

  @Action({ rawError: true })
  public async get({ url, pathParams, queryParams, cacheFunc, timeout = 5000, version = 'v1' }: { url: string; pathParams: any; queryParams: any; cacheFunc: () => void, timeout?: number, version?: string }): Promise<RestResult<any>> {
    this.context.commit('pending', { url, startTime: new Date().getTime() })

    while (this.ampServiceUrl === '') {
      const timeout = 1250
      await new Promise(resolve => setTimeout(resolve, timeout))
    }

    const currentRetries: number = this.restState[url].retries ? this.restState[url].retries : 0
    let axiosUrl: string = url

    try {
      pathParams.adminId = this.adminId

      Object.keys(pathParams).forEach(key => {
        axiosUrl = axiosUrl.replace(`:${key}`, pathParams[key])
      })

      const result: AxiosResponse = await axios.get(this.ampServiceUrl + axiosUrl, {
        headers: {
          'Content-Type': `application/vnd.starface.neon.${version}+json`
        },
        timeout: timeout,
        data: {}, // Empty-Data necessary for content-type header
        params: queryParams
      })

      this.context.commit('complete', { url, stopTime: new Date().getTime() })
      return new RestResult(currentRetries > 0 ? RequestStatus.SLOW : RequestStatus.NORMAL, result.data)
    } catch (error) {
      this.context.commit('retry', url)
      if (currentRetries < this.maxRetries) {
        return this.get({ url, pathParams, queryParams, cacheFunc, version })
      } else {
        this.context.commit('complete', { url, stopTime: new Date().getTime() })
        if (cacheFunc) {
          return new Promise(resolve => {
            resolve(new RestResult(RequestStatus.CACHED, cacheFunc()))
          })
        }
        return new Promise((_, reject) => {
          reject(new RestResult(RequestStatus.ERROR, {}))
        })
      }
    }
  }

  @Action({ rawError: true })
  public async post({ url, pathParams, body }: { url: string; pathParams: any; body: any}): Promise<any> {
    let axiosUrl: string = url
    pathParams.adminId = this.adminId

    Object.keys(pathParams).forEach(key => {
      axiosUrl = axiosUrl.replace(`:${key}`, pathParams[key])
    })

    return axios.post(this.ampServiceUrl + axiosUrl, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  @Action({ rawError: true })
  public async put({ url, pathParams, body, version = 'v1' }: { url: string; pathParams: any; body: any; version?: string}): Promise<any> {
    let axiosUrl: string = url
    pathParams.adminId = this.adminId

    Object.keys(pathParams).forEach(key => {
      axiosUrl = axiosUrl.replace(`:${key}`, pathParams[key])
    })

    return axios.put(this.ampServiceUrl + axiosUrl, body, {
      headers: {
        'Content-Type': `application/vnd.starface.neon.${version}+json`
      }
    })
  }

  @Action({ rawError: true })
  public async delete({ url, pathParams }: { url: string; pathParams: any}): Promise<any> {
    let axiosUrl: string = url
    pathParams.adminId = this.adminId

    Object.keys(pathParams).forEach(key => {
      axiosUrl = axiosUrl.replace(`:${key}`, pathParams[key])
    })

    return axios.delete(this.ampServiceUrl + axiosUrl, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  @Action
  public async configureBackendUrls(): Promise<any> {
    if (this.ampServiceUrl !== '') {
      return undefined
    }

    const deploymentMode: string | undefined = process.env.NODE_ENV
    if (deploymentMode === 'development') {
      const filename: string | undefined = process.env.VUE_APP_DEV_URL_FILE
      const urlConfig: AxiosResponse = await axios.get(`/assets/${filename}`)
      this.context.commit('storeAmpServiceUrl', urlConfig.data['amp-service-url'])
    } else {
      const urlConfig: AxiosResponse = await axios.get('./assets/prod-app-config.json')
      this.context.commit('storeAmpServiceUrl', urlConfig.data['amp-service-url'])
    }
  }

  @Action
  public storeTokenFromCookie(): void {
    const jwtToken: string = Vue.prototype.$cookies.get('jwt') as string
    if (!jwtToken) {
      window.location.pathname = '/login.html'
      return
    }
    this.context.dispatch('storeToken', jwtToken).catch()
  }

  @Action
  public storeToken(jwtToken: string): void {
    Vue.prototype.$cookies.set('jwt', jwtToken)

    const encoded: string = atob(jwtToken.split('.')[1])
    const jsonToken: any = JSON.parse(encoded)
    const milliToSecond = 1000
    const currentMillis: number = Math.floor(Date.now() / milliToSecond)
    if (jsonToken.exp <= currentMillis) {
      // Expired, reroute to login
      Vue.prototype.$cookies.remove('jwt')
      window.location.pathname = '/login.html'
      return
    }

    this.context.commit('storeAdminId', jsonToken.adminId)
    this.context.commit('storeRoles', jsonToken.roles)
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`

    if (this.refreshTimeoutAction) {
      clearTimeout(this.refreshTimeoutAction)
    }

    const gracePeriodInSeconds = 5
    const nowInSeconds: number = Math.floor(Date.now() / milliToSecond)
    const timeTillExpirationInSeconds: number = jsonToken.exp - nowInSeconds
    let timeout: number = timeTillExpirationInSeconds - gracePeriodInSeconds
    if (timeout <= 0) {
      timeout = 0
    }

    this.refreshTimeoutAction = setTimeout(() => {
      (axios.post(this.ampServiceUrl + '/tokens/refresh'))
          .then(newToken => {
            this.storeToken(newToken.data)
          }).catch(err => {
        Vue.prototype.$cookies.remove('jwt')
        window.location.pathname = '/login.html'
      })
    }, timeout * milliToSecond)
  }

  @Action
  public removeCookie(): void {
    Vue.prototype.$cookies.remove('jwt')
  }

  @Mutation
  public storeAdminId(adminId: string): void {
    this.adminId = adminId
  }

  @Mutation
  public storeRoles(roles: Role[]): void {
    this.roles = roles
  }

  @Mutation
  public storeAmpServiceUrl(ampServiceUrl: string): void {
    this.ampServiceUrl = ampServiceUrl
  }

  @Mutation
  public pending({ url, startTime }: { url: string; startTime: number }): void {
    if (!this.restState[url]) {
      Vue.set(this.restState, url, {})
    }

    this.restState[url].pending = true
    if (!this.restState[url].startTime) {
      this.restState[url].startTime = startTime
    }
  }

  @Mutation
  public complete({ url, stopTime }: { url: string; stopTime: number }): void {
    this.restState[url].pending = false
    this.restState[url].retries = 0
    this.restState[url].duration = stopTime - this.restState[url].startTime
  }

  @Mutation
  public retry(url: string): void {
    this.restState[url].retries = this.restState[url].retries ? this.restState[url].retries + 1 : 1
  }

  public get isPending(): (url: string) => boolean {
    return (url: string) => {
      return this.restState[url].pending
    }
  }

  public get getAdminId(): string {
    return this.adminId
  }

  public get hasAdminOrGivenRole(): (role: Role) => boolean {
    return (role) => (this.roles.includes('ROLE_ADMIN') || this.roles.includes(role))
  }

  public get getToken(): string {
    return Vue.prototype.$cookies.get('jwt') as string
  }

  public get getAmpServiceUrl(): string {
    return this.ampServiceUrl
  }
}
