




import { Component, Vue } from 'vue-property-decorator'
import SfTable from '@starface/sf-components/src/components/table/sf-table.vue'
import { BaseRestEntity } from '@/common'

// @ts-ignore
@Component({})
export default abstract class SfList extends Vue {

  protected abstract entityType: string;
  protected abstract deleteRestFunction: ((id: string) => Promise<any>) | undefined;

  protected listTable!: SfTable
  protected isMounted = false


  public beforeRouteEnter(to: any, from: any, next: any): void {
    next()
  }

  protected openDetailView(entity: BaseRestEntity): void {
    this.$router.push(`${this.$route.path}/${entity.id}`).catch()
  }

  protected onEditClicked(): void {
    this.openDetailView(this.selectedEntities[0] as BaseRestEntity)
  }

  protected onAddClicked(): void {
    this.$router.push(`${this.$route.path}/new`).catch()
  }

  protected onDeleteClicked(listTable: SfTable, messageParameters: Record<string, unknown> = {}): void {
    this.$dialog
      .confirm({
        message: `${this.entityType}.delete.confirmMessageMulti`,
        messageParameters: { commaSeparatedNames: this.commaSeparatedNames(), ...messageParameters }
      })
      .then(() => listTable.setLoading())
      .then(() => Promise.all(this.selectedEntities.map(entity => {
        if (this.deleteRestFunction !== undefined) {
          this.deleteRestFunction((entity as unknown as BaseRestEntity).id).catch()
        }
      })))
      .then(() => this.$toast.success(`${this.entityType}.delete.successMessage`))
      .finally(() => listTable.setReady())
      .catch()
  }

  protected get selectedEntities(): Array<any> {
      return []
  }

  protected commaSeparatedNames(): string {
    return ''
  }
}
