











































































import { Ref, Component, Vue } from 'vue-property-decorator'
import { SfForm } from '@starface/sf-components/types/components'
import SfTable, { RowFunction } from '@starface/sf-components/types/components/table/sf-table.vue'
import Meeting from '@/customer-portal/meeting/entities/meeting'
import { getAllMeetingsByTimeInterval } from '@/customer-portal/meeting/services/meeting-rest'
import moment from 'moment'

enum Mode { all, exclude, select }

interface Settings {
  from: Date;
  to: Date;
  mode: Mode
  pbxIds: Array<string>;
}

interface FormSettings {
  from: string;
  to: string;
  mode: { key: number, value: string };
  pbxIds: string;
}

interface Report {
  meetingId: string;
  type: string;
  paymentPlan: string;
  pbxId?: string;
  pbxLink?: {
    url: string;
    text: string;
  },
  participantCount: number;
  consumerCount: number;
  ownerId: string;
  startTime: string;
  duration: string;
  minutes: string;
  uniqueId: string;  // used for routing only
  remoteControlMinutes: string;
}

const csvHeaders = [
  'meetingId',
  'meetingType',
  'paymentPlan',
  'pbxId',
  'participantCount',
  'consumerCount',
  'ownerId',
  'startTimestamp',
  'duration',
  'minutes',
  'remoteControlMinutes'
]

@Component({ components: {} })
export default class MeetingReport extends Vue {

  @Ref()
  public readonly settingsForm!: SfForm

  @Ref()
  private readonly reportTable: SfTable

  private lastSearch: Settings | null = null
  private reports: Report[] = []
  private noMeetingsFound = false
  private modeIsAll = true

  private rowFunctions: RowFunction[] = [
    {
      name: 'meetingDetailView', icon: 'eye', color: 'orange',
      onclick: (index, row, item?: any) => this.goToMeetingDetailView(item.uniqueId)
    }
  ]

  private onModeChanged(key: string) {
    this.modeIsAll = parseInt(key) === 0
  }

  private showReport(): void {
    this.search()
        .then(reports => {
          this.reports = reports
          this.noMeetingsFound = reports.length === 0
        }).catch()
  }

  private createCsv(): void {
    this.search()
        .then(reports => this.downloadReports(reports)).catch()
  }

  private newSearch(): void {
    this.reports = []
    this.noMeetingsFound = false
    this.lastSearch = null
  }

  public goToMeetingDetailView(id: string): void {
    this.$router.push(`/meetings/${id}`).catch()
  }

  private search(): Promise<Array<Report>> {
    this.reportTable.setLoading()

    try {
      const settings = this.getSettings()
      this.lastSearch = settings

      return getAllMeetingsByTimeInterval(settings.from, settings.to, 0, [])
          .then(meetings => {
            console.log(meetings)

            if (settings.mode == Mode.all) {
              return meetings
            }

            const predicate = settings.mode === Mode.exclude
                ? (meeting: Meeting) => !settings.pbxIds.includes(meeting.pbxId)
                : (meeting: Meeting) => settings.pbxIds.includes(meeting.pbxId)

            return meetings.filter(predicate)
          })
          .then(meetings => meetings.map(this.meetingToReport))
          .catch(() => {
            this.$toast.error('report.meeting.error.fetch')
            return []
          })
          .finally(() => this.reportTable.setReady())
    } catch (e) {
      this.$toast.error(e)
      this.reportTable.setReady()
      return Promise.resolve([])
    }
  }

  private downloadReports(reports: Array<Report>) {
    const data = reports.map(this.reportToCsv).join('\n')
    const blob = new Blob([csvHeaders.join(','), '\n', data], { type: 'text/csv;charset=utf-8' })

    const a = window.document.createElement('a')

    a.setAttribute('target', 'blank')
    a.setAttribute('href', window.URL.createObjectURL(blob))
    a.setAttribute('download', 'meeting-report.csv')

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  private getSettings(): Settings {
    const formSettings = this.settingsForm.getData<FormSettings>()

    const from = this.parseDate(formSettings.from)
    const to = this.parseDate(formSettings.to)
    const mode = formSettings.mode.key
    const pbxIds = formSettings.pbxIds === '' ? [] : formSettings.pbxIds?.split(/\s+/)

    from.setHours(0, 0, 0, 0)
    to.setHours(23, 59, 59, 999)

    return { from, to, mode, pbxIds }
  }

  private parseDate(stringDate: string): Date {
    const re = /^(?<year>\d{4})-(?<month>1[0-2]|0\d|)-(?<day>0[1-9]|[12]\d|3[01])$/  // YYYY-MM-DD

    const match = stringDate.match(re)
    if (!match || !match.groups) {
      throw `Invalid date format ${stringDate}, expected 'YYYY-MM-DD'`
    }

    const year = parseInt(match.groups.year)
    const month = parseInt(match.groups.month) - 1
    const day = parseInt(match.groups.day)

    if ([1, 3, 5, 8, 10].indexOf(month) >= 0 && day == 31) {
      throw `Invalid day ${day} for month ${month}`
    } else if (month === 1) {
      // Gregorian calendar rule for leap years
      const leapYear = year % 4 === 0 && (year % 100 != 0 || year % 400 === 0)
      if (day == 30 || !leapYear && day == 29) {
        throw `Invalid day: 29th of April in ${year} (not a Gregorian calendar leap year)`
      }
    }

    return new Date(year, month, day)
  }

  private meetingToReport(meeting: Meeting): Report {
    const { meetingId, pbxId, participantCount, addOns } = meeting
    const consumerCount = participantCount * (participantCount - 1)

    const durationMoment = moment.utc(moment(meeting.endTimestamp).diff(moment(meeting.startTimestamp)))
    const duration = durationMoment.format('HH:mm:ss')

    // The minutes report needs to be rounded up to full minutes
    if (durationMoment.seconds() !== 0) {
      durationMoment.seconds(0)
      durationMoment.minutes(durationMoment.minutes() + 1)
    }

    const minutes = `${durationMoment.unix() / 60}`
    const remoteControlMinutes = addOns.find(a => a.name === 'remote-control')?.duration ?? '0'

    return {
      meetingId,
      type: meeting.meetingType,
      paymentPlan: meeting.metaData?.paymentPlan ?? '',
      pbxId,
      pbxLink: {
        url: '/#/pbx/' + pbxId,
        text: pbxId
      },
      participantCount,
      consumerCount,
      ownerId: meeting.meetingOwnerId,
      startTime: meeting.startTimestamp,
      duration,
      minutes,
      uniqueId: meeting.id,
      remoteControlMinutes
    }
  }

  private reportToCsv(report: Report): string {
    const {
      meetingId,
      type,
      paymentPlan,
      pbxId,
      participantCount,
      consumerCount,
      ownerId,
      startTime,
      duration,
      minutes,
      remoteControlMinutes
    } = report
    return [meetingId, type, paymentPlan, pbxId, participantCount, consumerCount, ownerId, startTime, duration, minutes, remoteControlMinutes].join(',')
  }
}
