import { render, staticRenderFns } from "./admin-editor.vue?vue&type=template&id=1423fee0&scoped=true&"
import script from "./admin-editor.vue?vue&type=script&lang=ts&"
export * from "./admin-editor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1423fee0",
  null
  
)

export default component.exports