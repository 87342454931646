import { render, staticRenderFns } from "./file-editor.vue?vue&type=template&id=42f96979&scoped=true&"
import script from "./file-editor.vue?vue&type=script&lang=ts&"
export * from "./file-editor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f96979",
  null
  
)

export default component.exports