import MeetingMetaData from '@/customer-portal/meeting/entities/meeting-meta-data'
import MeetingClientData from '@/customer-portal/meeting/entities/meeting-client-data'
import AddOn from '@/customer-portal/meeting/entities/add-on'

export default class Meeting {
  public id = ''
  public meetingId = ''
  public meetingSessionId = ''
  public meetingAlias: string | undefined = ''
  public meetingType = ''
  public participantCount = 0
  public pbxId = ''
  public meetingOwnerId = ''
  public startTimestamp = ''
  public endTimestamp = ''
  public clientData: MeetingClientData | undefined = undefined
  public metaData: MeetingMetaData | undefined = undefined
  public addOns: AddOn[] = []
}
