import { RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import PbxResponse from '@/customer-portal/pbx/entities/pbx-response'
import { Admin } from '@/customer-portal'
import { PbxUpdate } from '@/customer-portal/pbx/entities/pbx'

interface RequestParams {
  url: string;
  pathParams: any;
  queryParams: any;
  version?: string;
  cacheFunc: () => void;
}

const restModule: RestModule = getModule(RestModule, store)

const getPbxIdByServerLicenseKey: (serverLicenseKey: string) => Promise<RestResult<any>> = async (serverLicenseKey: string) => {
  const idRequest: RequestParams = {
    url: '/pbx/',
    pathParams: {},
    queryParams: { serverLicenseKey },
    cacheFunc: () => []
  }

  return restModule.get(idRequest)
}

export const getPbxById: (id: string) => Promise<PbxResponse> = async (id: string) => {
  const request: RequestParams = {
    url: '/pbx/:pbxId',
    pathParams: { pbxId: id },
    queryParams: {},
    version: 'v3',
    cacheFunc: () => []
  }

  return {
    items: [(await restModule.get(request)).data]
  }
}

export const getPbxByLicenseKey: (serverLicenseKey: string) => Promise<PbxResponse> = async (serverLicenseKey: string) => {
  try {
    const pbxIdResponse: RestResult<any> = await getPbxIdByServerLicenseKey(serverLicenseKey)
    const pbxResponse: PbxResponse = await getPbxById(pbxIdResponse.data.items[0].id)

    return pbxResponse
  } catch (e) {
    return new PbxResponse()
  }
}

export const updatePbX: (id: string, pbx: PbxUpdate) => Promise<string> =
  (id: string, pbx: PbxUpdate): Promise<string> => restModule.put({
    url: '/pbx/:pbxId',
    pathParams: { pbxId: id },
    version: 'v3',
    body: pbx
  }).then((result: RestResult<string>) => {
    return result.data
  })
