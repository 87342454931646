































import { Ref, Component, Vue } from 'vue-property-decorator'
import { getMeetingByMeetingId } from '@/customer-portal/internal-meeting/services/meeting-rest'

@Component({ components: {} })
export default class InternalMeetingSearch extends Vue {

  @Ref()
  private readonly searchField: HTMLInputElement

  private awaitRestResponse = false
  private noMeetingFound = false
  private searchedMeeting = ''

  public async search(): Promise<void> {
    this.awaitRestResponse = true
    const getMeetingRestResult = await getMeetingByMeetingId(this.searchedMeeting).catch((e) => {
      console.error('failed to get meeting by meetingId', e)
      return { status: 404 }
    })
    this.awaitRestResponse = false
    if (getMeetingRestResult.status === 404) {
      this.noMeetingFound = true
      return
    }
    this.$router.push(`${this.$route.path}/${this.searchedMeeting}`).catch()
  }

}
