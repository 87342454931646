import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { SurveyOverview, SurveyList } from '..'

@Service({ id: routeToken, multiple: true })
export class SurveyRoute implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/surveys',
      component: SurveyOverview,
      children: [
        {
          path: '/',
          component: SurveyList,
          meta: { entity: 'survey' }
        }],
      props: true
    }
  }
}
