





import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
export default class Token extends Vue {
  private mounted(): void {
    const token: string = Vue.prototype.$cookies.get('jwt') as string

    if (token) {
      this.$rest.storeToken(token)
    }

    this.$router.replace('/').catch()
    this.$router.push('/overview').catch()
  }
}
