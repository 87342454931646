import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { MeetingReport } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class MeetingReportRoutes implements RouteDefinition {
  public getRouteConfig(): RouteConfig {
    return {
      path: '/report/meetings',
      component: MeetingReport
    }
  }
}
