import { Container } from 'typedi'
import { ActorRoutes } from './services/actor-routes'

Container.import([
    ActorRoutes
])

export { default as ActorSearch } from './actor-search.vue'
export { default as ActorOverview } from './actor-overview.vue'

