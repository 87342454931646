import { RequestStatus, RestModule, RestResult } from '@/services'
import { getModule } from 'vuex-module-decorators'
import { store } from '@/services/store/store'
import { Admin } from '@/customer-portal'

const restModule: RestModule = getModule(RestModule, store)

export const getAdmin: (id: string) => Promise<Admin> = (id: string): Promise<Admin> => restModule.get({
  url: '/admins/:otherAdminId',
  pathParams: { otherAdminId: id },
  queryParams: {},
  cacheFunc: () => undefined
}).then((result: RestResult<Admin>) => {
  return result.data
})

export const getAdmins: () => Promise<Admin[]> = (): Promise<Admin[]> => restModule.get({
  url: '/admins',
  pathParams: {},
  queryParams: {},
  cacheFunc: () => []
}).then((result: RestResult<Admin[]>) => {
  switch (result.status) {
    case RequestStatus.SLOW: // Toast (doof)
    case RequestStatus.NORMAL: {
      return result.data
    }
    case RequestStatus.CACHED:
    case RequestStatus.ERROR:
    default: {
      // Toast(kaputt)
      return []
    }
  }
})

export const updateAdmin: (id: string, admin: Admin) => Promise<string> =
  (id: string, admin: Admin): Promise<string> => restModule.put({
    url: '/admins/:otherAdminId',
      pathParams: { otherAdminId: id },
    body: admin
  }).then((result: RestResult<string>) => {
    return result.data
  })

export const createAdmin: (admin: Admin) => Promise<string> =
  (admin: Admin): Promise<string> => restModule.post({
    url: '/admins/',
    pathParams: {},
    body: admin
  }).then((result: RestResult<string>) => {
    return result.data
  })

export const deleteAdmin: (id: string) => Promise<string> = (id: string): Promise<string> => restModule.delete({
  url: '/admins/:otherAdminId',
  pathParams: { otherAdminId: id },
})
