import { RouteConfig } from 'vue-router'
import { Service } from 'typedi'
import { RouteDefinition, routeToken } from '@/services'
import { ActorOverview, ActorSearch } from '@/customer-portal'

@Service({ id: routeToken, multiple: true })
export class ActorRoutes implements RouteDefinition {
    public getRouteConfig(): RouteConfig {
        return {
            path: '/actor',
            component: ActorOverview,
            children: [
                {
                    path: '/',
                    component: ActorSearch,
                    meta: { entity: 'actor' }
                }
            ],
            props: true
        }
    }
}