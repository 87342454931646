





































import { Ref, Component, Vue } from 'vue-property-decorator'
import { Admin, deleteAdmin, Survey, getAdmins } from '@/customer-portal'
import { SfList } from '@/components'
import { SfContentWrapper, SfStringCell } from '@starface/sf-components/src/components/'
import { SfTable } from '@starface/sf-components/types/components'

@Component({
  components: { SfContentWrapper, SfStringCell }
})
export default class AdminList extends SfList {

  @Ref()
  private readonly adminTable!: SfTable

  protected entityType = 'admin'

  protected deleteRestFunction: ((id: string) => Promise<string>) | undefined = async(id: string) => {
    return deleteAdmin(id).then((result: string) => {
      this.loadAdmins()
      return result
    })
  }

  private admins: Admin[] = []

  private loadAdmins(): void {
    this.adminTable.setLoading()
    getAdmins().then(admins => {
      this.admins = Array.from(admins.values())
      this.adminTable.setReady()
      this.isMounted = true
    }).catch()
  }

  public mounted(): void {
    this.loadAdmins()
  }

  protected get selectedEntities(): Admin[] {
    if (this.isMounted) {
      return this.adminTable.getSelectedRows() as Admin[]
    }
    return []
  }

  protected commaSeparatedNames(): string {
    return this.selectedEntities.map(entity => entity.firstName + ' ' + entity.lastName).join(', ')
  }

  private get adminSelected(): boolean {
    return !!this.selectedEntities.find((admin: Admin) => admin.id === this.$rest.getAdminId)
  }
}
